<template>
  <div id="jcom-table-edit">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ $t("My Table Members Status") }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label class="my-txt1">Membership Id</label>
                    <p class="my-txt2">{{ recordData.MembershipId }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label class="my-txt1">Member Name</label>
                    <p class="my-txt2">{{ recordData.MemberName }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label class="my-txt1">Company Name</label>
                    <p class="my-txt2">{{ recordData.CompanyName }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label class="my-txt1">Category</label>
                    <p class="my-txt2">{{ recordData.BusinessName }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label class="my-txt1">Connect Given</label>
                    <p class="my-txt2">{{ recordData.ConnectGiven }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label class="my-txt1">Connect Recived</label>
                    <p class="my-txt2">{{ recordData.ConnectRecived }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label class="my-txt1">Gnote Given</label>
                    <p class="my-txt2">{{ recordData.GnoteGiven }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <label class="my-txt1">Gnote Recived</label>
                    <p class="my-txt2">{{ recordData.GnoteRecived }}</p>
                  </v-col>
                  <v-col cols="12" lg="12" sm="12" md="12">
                    <v-divider />
                    <label>
                      <h3><span class="text-danger">*</span> Member Reports</h3>
                      <!-- <p class="my-txt2">{{ recordData.Description }}</p> -->
                      <h4 v-html="recordData.Description"></h4>
                    </label>
                    <v-textarea
                      outlined
                      v-model="Reason"
                      :rules="ReasonRules"
                      name="input-7-4"
                      label="Reason"
                      value=""
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert(3)"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#8B0000"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Block member
                  </v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert(3)"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#1db954"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Unblock
                  </v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert(3)"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#1db954"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    block member
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#a52a2a"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      TableNameRules: [(v) => !!v || "Member Details is required"],
      TableName: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.LaunchDate = todayDate;

        this.pageData();
        this.getTableRecords();
      }
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_table",
        Action: "edit",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.LoadingFlag = false;

      var recordData = this.recordData;
      console.log("recordData = " + JSON.stringify(recordData));
    },
    pageData() {
      this.getYearCodeOptions();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    getYearCodeOptions() {
      console.log("getYearCodeOptions called");
      var CurrentYearId = this.CurrentYearId;
      console.log("CurrentYearId=" + CurrentYearId);
      this.JciYearCodeOptionsLoading = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    confirmAlert(status) {
      console.log({ status });
      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      if (validate1) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.updateActiveStatus(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
    updateActiveStatus(status) {
      console.log("updateActiveStatus is called");

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      var Status = status;
      var Reason = this.Reason;
      var tr = this.recordData;
      // var Id = recordData.JcomYearwiseMembershipId;
      console.log({ tr });
      if (validate1) {
        this.LoadingFlag = true;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/jcom/membership/jcom-members-status-update";
        var upload = {
          UserInterface: 1,
          Status: Status,
          Reason: Reason,
          MembershipId: tr.MembershipId,
          MemberId: tr.MemberId,
          JcomYearwiseMembershipId: tr.JcomYearwiseMembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, true);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
#module-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
.my-txt2 {
  font-size: 16px;
  // font-weight: bold;
}
</style>